:export{black:#000;gray:#eff2f5;blue:#0068dc;blue_2:#0db9dd;border_gray:rgba(107,120,134,.2);light_blue:#003876;light_blue_2:rgba(0,143,255,.1);light_gray:#38506a;input_border_gray:rgba(107,120,134,.5);white:#fff;dark_gray:#646363;green:#09ad05;red:#ff3232;gold:#f8b54c;gold_2:#fc6;orange:#ff8e00}*{padding:0;margin:0;box-sizing:border-box}body{background-color:#eff2f5}a{text-decoration:none}ul{list-style:none}.ant-tooltip-inner{color:#000 !important;background:#fff !important}.ant-tooltip-arrow{display:none !important}.ant-btn>span{font-weight:500 !important}.ant-btn.ant-btn-link,.ant-btn.ant-btn-text>div{border-radius:0 !important}.ant-btn.ant-btn-link{padding:0 !important}.ant-btn.ant-btn-primary{box-shadow:none !important;background-color:#003876}.ant-btn.ant-btn-default{border-color:#003876;color:#003876}.ant-table .ant-table-tbody .ant-table-cell{line-height:18px !important}.ant-table .ant-table-tbody .ant-table-cell>div,.ant-table .ant-table-tbody .ant-table-cell>span,.ant-table .ant-table-tbody .ant-table-cell>p{line-height:18px !important}.ant-table .ant-table-tbody .ant-table-cell .ant-typography{line-height:18px !important}.ant-table-cell::before{display:none}.ant-table-column-sorters{gap:2px;color:#0068dc}.ant-typography-copy{height:16px !important;margin-left:6px !important;display:inline !important;position:relative}.ant-typography-copy svg,.ant-typography-copy span{position:absolute;top:0;left:0}.ant-modal-content{border-radius:16px !important}.ant-modal-header{border-top-right-radius:16px !important;border-top-left-radius:16px !important;margin-bottom:0 !important}.ant-select-selection-overflow{flex-wrap:nowrap !important;gap:5px}.ant-select-selection-overflow-item{flex-shrink:1 !important;min-width:50px}.ant-select-selection-overflow-item-rest{flex-shrink:0 !important}.ant-picker{height:40px !important}
:export{black:#000;gray:#eff2f5;blue:#0068dc;blue_2:#0db9dd;border_gray:rgba(107,120,134,.2);light_blue:#003876;light_blue_2:rgba(0,143,255,.1);light_gray:#38506a;input_border_gray:rgba(107,120,134,.5);white:#fff;dark_gray:#646363;green:#09ad05;red:#ff3232;gold:#f8b54c;gold_2:#fc6;orange:#ff8e00}.global_GPB *{font-family:var(--font-tt-norms),sans-serif !important}.global_GPB input::placeholder,.global_GPB textarea::placeholder{font-family:var(--font-tt-norms),sans-serif}.global_PIRSON *{font-family:var(--font-inter),sans-serif !important}.global_PIRSON input::placeholder,.global_PIRSON textarea::placeholder{font-family:var(--font-inter),sans-serif}.global_ROS *{font-family:var(--font-exo2),sans-serif !important}.global_ROS input::placeholder,.global_ROS textarea::placeholder{font-family:var(--font-exo2),sans-serif}.hint-icon{color:#003876;flex-shrink:0;cursor:help}.v-icon{color:#003876;transition:transform .25s linear}.input-line{padding:0 !important;font-size:18px !important;border-radius:0 !important;color:#000 !important;border-bottom:1px solid rgba(107,120,134,.5) !important}.input-line::placeholder{color:#38506a !important;opacity:.5 !important}.input-line:focus,.input-line:hover{border-bottom-color:#4096ff !important}.unauthorized-modal .ant-modal-confirm-title{font-weight:500 !important}.unauthorized-modal .ant-modal-confirm-btns{margin-top:30px !important}.table-with-sticky-footer{position:relative !important}.table-with-sticky-footer .ant-table-footer{position:sticky;bottom:0;z-index:10}.button-with-icon{display:flex !important;align-items:center}
@font-face {
font-family: '__ttNorms_753a69';
src: url(/_next/static/media/13b445fbd468076d-s.p.otf) format('opentype');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__ttNorms_753a69';
src: url(/_next/static/media/40182b9efd14441d-s.p.otf) format('opentype');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: '__ttNorms_753a69';
src: url(/_next/static/media/d9fdabf4773b6b64-s.p.otf) format('opentype');
font-display: swap;
font-weight: 600;
font-style: normal;
}

@font-face {
font-family: '__ttNorms_753a69';
src: url(/_next/static/media/8b42eac2865b5431-s.p.otf) format('opentype');
font-display: swap;
font-weight: 700;
font-style: normal;
}@font-face {font-family: '__ttNorms_Fallback_753a69';src: local("Arial");ascent-override: 96.89%;descent-override: 22.23%;line-gap-override: 0.00%;size-adjust: 99.08%
}.__className_753a69 {font-family: '__ttNorms_753a69', '__ttNorms_Fallback_753a69'
}.__variable_753a69 {--font-tt-norms: '__ttNorms_753a69', '__ttNorms_Fallback_753a69'
}

